/* WIDGET: TESTIMONIAL
-------------------------------------------- */

.ptf-testimonial {
  --ptf-text-align: center;
  --ptf-info-color: var(--ptf-color-3);
  --ptf-info-span-color: var(--ptf-color-2);
  --ptf-content-color: var(--ptf-color-black);
  --ptf-author-color: var(--ptf-color-black);
}

.ptf-testimonial {
  text-align: var(--ptf-text-align);

  &__content {
    color: var(--ptf-content-color);
    font-family: var(--ptf-secondary-font);
    font-size: px2rem(48px);
    line-height: 1.375;

    @include mq("large") {
      font-size: px2rem(36px);
    }

    a {
      color: #09f;
    }
  }

  &__meta {
    margin-top: px2rem(65px);
  }

  &__author {
    color: var(--ptf-author-color);
    font-size: px2rem(24px);
  }

  &__info {
    margin-top: px2rem(10px);

    color: var(--ptf-info-color);
    font-size: px2rem(14px);

    span {
      color: var(--ptf-info-span-color);
    }

    a {
      color: #09f;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* SECTION: STYLE 2
-------------------------------------------- */

.ptf-testimonial--style-2 {
  .ptf-testimonial__content {
    font-family: var(--ptf-primary-font);
    line-height: 1.33;
    font-weight: 700;
  }

  .ptf-testimonial__meta {
    margin-top: px2rem(75px);
  }

  .ptf-testimonial__info {
    margin-top: px2rem(5px);
  }
}

/* SECTION: STYLE 3
-------------------------------------------- */

.ptf-testimonial--style-3 {
  .ptf-testimonial__content {
    font-family: var(--ptf-primary-font);
    line-height: 1.5;
    font-weight: normal;
  }

  .ptf-testimonial__meta {
    margin-top: px2rem(65px);
  }

  .ptf-testimonial__author {
    font-size: px2rem(24px);
    font-weight: normal;
  }
}

/* SECTION: STYLE 4
-------------------------------------------- */

.ptf-testimonial--style-4 {
  .ptf-testimonial__content {
    font-family: var(--ptf-primary-font);
    font-size: px2rem(36px);
    line-height: 1.55;
  }

  .ptf-testimonial__meta {
    margin-top: px2rem(75px);
  }

  .ptf-testimonial__info {
    margin-top: px2rem(5px);
  }
}
.space-80 {
  padding: 0 80px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.space-150 {
  padding: 0 150px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.space-180 {
  padding: 0 380px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.space-500 {
  padding: 0 50%;
  width: 150%;
  textAlign: 'center';
  @media (max-width: 767px) {
    padding: 0;
  }
}
.space-300 {
  padding-right: 0% !important;
  padding: 0 35%;
  width: 150%;
  textAlign: 'center';
  @media (max-width: 1200px) {
    padding: 0 20% !important;
    margin-top: 30%;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}
.space-400{
  padding-right: 0% !important;
  padding: 0 35%;
  //width: 150%;
  textAlign: 'center';
  @media (max-width: 1200px) {
    padding: 0 20% !important;
    margin-top: 30%;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}
.margin-left-250 {
  margin-right:22%;
  margin-top: -39%;
  width: 150%;
  textAlign: 'center';
  @media (max-width: 767px) {
    padding: 0;
    margin-top:-3%;
  }
}
.padding40{
  padding-left: 30%;
  @media (max-width: 767px) {
    padding-left: -22%;
  }
}
.margin-left-240 {
  margin-right:25%;
  margin-top: -23%;
  width: 150%;
  textAlign: 'center';
  @media (max-width: 767px) {
    padding: 0;
  }
}
.margin-left-500 {
  margin-right:50%;
  margin-top: -23%;
  width: 150%;
  textAlign: 'center';
  @media (max-width: 767px) {
    padding: 0;
    margin-right: 0%;
    margin-top: 0%;
  }
}
.margin-250 {
  margin: 0 40%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-260 {
  margin: 0 45%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-500 {
  margin: 0 60%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-504 {
  margin: 0 54%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-150 {
  margin: 0 25%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-130 {
  margin: 0 28%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-100 {
  margin: 0 20%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-90 {
  margin: 0 14%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.margin-120 {
  margin: 0 28%;
  width: 100%;
  textAlign: 'center';
  @media (max-width: 767px) {
    margin: 0;
  }
}
.testimonial-pagination--style-bottom {
  .slick-dots {
    bottom: -60px;
  }
}
p{
  font-size: 20px;
  font-family: 'Montserrat';
}