/* LAYOUT: GRID
-------------------------------------------- */

@media (min-width: 1580px) {
  .container-xxl {
    max-width: 1550px;
  }
}

.container-contact-us{
  display: flex;
  justify-content: center;
}

.container-avg {
  display: flex;
  justify-content: flex-start;
  padding-top:20px;
}

.container-name-input{
  display: flex;
}

.container-socials {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left:125px;
}

.container-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-y {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-inline:50px;
}

.ptf-main {
  overflow: hidden;
}

.ptf-site-wrapper__inner {
  position: relative;
  background-color: var(--ptf-color-white);
}
